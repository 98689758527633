(function() {

    //the callback function that will be fired
    //when the element apears in the viewport
    //https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    function onEntry(entry) {

        entry.forEach((change) => {

            if (change.isIntersecting) {

                if (change.target.classList.contains('in-view')) {

                    return;

                } else {

                    var fn = window[change.target.dataset.trigger];
                    if (typeof fn === "function") fn();

                }

                change.target.classList.add('in-view');

            }

        });
    }

    let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (viewportWidth > 600) {

        var options = {

            threshold: [1],
            rootMargin: '-50px'

        };

    } else {

        var options = {

            threshold: [0.5],

        };

    }

    // instantiate a new Intersection Observer
    let observer = new IntersectionObserver(onEntry, options);

    // list of paragraphs
    let elements = document.querySelectorAll('.animate-on-scroll');

    // loop through all elements
    // pass each element to observe method
    // ES2015 for-of loop can traverse through DOM Elements
    for (let elm of elements) {
        observer.observe(elm);
    }

}());

//fire up the text animation
introAnimation();
makeSquare();

//get the button ready to animate
let elements = document.querySelectorAll('#contact-cta');
convertTextToSpan(elements);

animateButton();

function animateButton() {

    let buttonAnimation = anime({

        targets: '#contact-cta .letter',
        color: '#000',
        delay: anime.stagger(30),
        autoplay: false,

    });

    let button = document.getElementById('contact-cta');

    function animateButton() {

        buttonAnimation.play();
        button.removeEventListener('mouseenter', animateButton, false);

    }

    if (button) {

        button.addEventListener('mouseenter', animateButton, false);

    }

}

//product management animations
function animateProductManagement() {

    let timeline = anime.timeline();

    timeline.add({

        targets: '#bulb-illustration-wrapper',
        opacity: [0, 1],
        translateX: [-50, 0],
        duration: 600,

    })

    timeline.add({

        targets: '#bulb-illustration-wrapper #glass-fill',
        opacity: [0, 0.9],
        duration: 600,
        easing: 'easeInSine',

    }, '-=100')

    timeline.add({

        targets: '#bulb-illustration-wrapper #rays path',
        opacity: 0.7,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInSine',
        duration: 200,
        delay: anime.stagger(20)

    }, '-=100')

    timeline.finished.then(function() {

        anime({

            targets: '#bulb-illustration-wrapper #glass-fill',
            opacity: [0.9, 0.5],
            duration: 1000,
            easing: 'easeInOutSine',
            loop: true,
            direction: 'alternate',
            delay: 500,

        })

    })

}

//web development animations
function animateWebDevelopment() {

    let timeline = anime.timeline();

    timeline.add({

        targets: '#dev-illustration-wrapper',
        opacity: [0, 1],
        translateX: [50, 0],
        duration: 600,

    })

    timeline.add({

        targets: '#dev-illustration-wrapper #image-b',
        opacity: 1,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'linear',
        duration: 800,
    })

    timeline.add({

        targets: '#dev-illustration-wrapper #image-b',
        fill: ['rgba(251, 213, 91, 0)', 'rgba(251, 213, 91, 1)'],
        duration: 100,
        easing: 'linear'

    })

    timeline.add({

        targets: '#dev-illustration-wrapper #reflection-b',
        opacity: [0, 1],
        duration: 100,
        easing: 'linear'

    })

    timeline.add({

        targets: '#dev-illustration-wrapper #text path',
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 400,
        easing: 'linear',
        delay: anime.stagger(40)

    })

    /* timeline.finished.then(function() {

        anime({

            targets: '#dev-illustration-wrapper #website',


        })

    }) */

    /* timeline.add({

        targets: '#dev-illustration-wrapper',
        opacity: [0, 1],
        translateX: [50, 0],
        duration: 600,

    })

    timeline.add({

        targets: '#dev-illustration-wrapper #code path',
        opacity: 1,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInSine',
        duration: 200,
        delay: anime.stagger(40),
        endDelay: 1000

    })

    timeline.finished.then(function() {

        anime({

            targets: '#dev-illustration-wrapper #leds path',
            opacity: [0, 1],
            duration: 200,
            loop: true,
            // direction: 'reverse',
            easing: 'easeInOutSine',
            delay: anime.stagger(500),
            endDelay: 1000,

        })

    }) */

}

//measurement shuttle animations
function animateMeasurement() {

    let timeline = anime.timeline();

    timeline.add({

        targets: '#shuttle-illustration-wrapper',
        opacity: [0, 1],
        translateX: [-50, 0],
        duration: 600,

    })

    timeline.add({

        targets: '#shuttle-illustration-wrapper #smoke',
        translateY: [0, 600],
        duration: 1000,
        easing: 'easeInSine',

    })

    timeline.finished.then(function() {

        anime({

            targets: '#shuttle-illustration-wrapper #thrust-left path,  #shuttle-illustration-wrapper #thrust-right path',
            opacity: 1,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInSine',
            duration: 300,
            loop: true,
            endDelay: 1500,
            direction: 'alternate',

        })

        anime({

            targets: '#shuttle-illustration-wrapper #lights path',
            opacity: [1, 0],
            easing: 'linear',
            duration: 300,
            loop: true,
            endDelay: 1000,

        })

    })

}

function animateHuman() {

    anime({

        targets: '#human-illustration-wrapper #hair',
        d: ['M261.466 45S274 22 248.933 22c-25.068 0-57.366 4-82.433-17 0 11.667-24.104 28.333-7.392 40-17.408-4.667-25.764 5-25.068 29 37.601 0 96.092-2 103.403-22 0 18.667 12.186 30.333 36.557 35V45h-12.534z', 'M261.466 45S274 22 248.933 22c-25.068 0-89.825 9-114.893-12 0 11.667 8.356 23.333 25.068 35-17.408-4.667-25.764 5-25.068 29 37.601 0 96.092-2 103.403-22 0 18.667 12.186 30.333 36.557 35V45h-12.534z'],
        duration: 1000,
        delay: 500,

    })

    anime({

        targets: '#human-illustration-wrapper #right-eye, #human-illustration-wrapper #left-eye',
        loop: true,
        translateX: 5,
        translateY: 4,
        direction: 'alternate',
        duration: 400,
        endDelay: 2000,
        easing: 'linear',
        delay: 800

    })

}

function animateProduct() {

    anime({

        targets: '#product-illustration-wrapper svg',
        rotate: 200,
        loop: true,
        direction: 'alternate',
        duration: 1000

    })

}

function introAnimation() {

    let elements = document.querySelectorAll('#intro-heading, #intro-strap');

    convertTextToSpan(elements);

    //hide everything in the wrappers until the text conversion has taken place
    let wrappers = document.querySelectorAll('#intro-heading, #intro-strap');

    wrappers.forEach((wrapper) => {

        wrapper.style.opacity = 1;

    })

    let timeline = anime.timeline();

    timeline.add({

        targets: '#intro-heading .letter',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 600,
        delay: anime.stagger(30),

    })

    timeline.add({

        targets: '#intro-strap .letter',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 600,
        delay: anime.stagger(30),

    }, '+=600')

    if (window.innerWidth > 767) {

        timeline.add({

            targets: '#intro-citation',
            opacity: [0, 1],
            easing: 'linear',
            duration: 1000,

        })

    }

}

function convertTextToSpan(elements) {

    //loop through the elements and wrap letters in span
    for (let item of elements) {

        let string = item.innerHTML;
        let html = '';

        for (var i = 0; i < string.length; i++) {

            html = html + '<span class="letter">' + string.charAt(i) + '</span>';

        }

        item.innerHTML = html;

    }

}

function textAnimation() {

    // Wrap every letter in a span
    var animatedTextElements = document.getElementsByClassName('animated-text')

    convertTextToSpan(animatedTextElements);

    anime({

        targets: '.animated-text .letter',
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 600,
        delay: anime.stagger(30),

    })

}

/**
 * set and maintain aspect ratio of elements
 * @function
 * @name aspectRatio
 */
function makeSquare() {

    let elements = document.querySelectorAll('.square');

    elements.forEach((element) => {

        element.style.height = element.offsetWidth + 'px';

    })

    squareResizeListener();

};

var timer_id;

function squareResizeListener() {

    window.addEventListener('resize', function() {

        clearTimeout(timer_id);

        timer_id = setTimeout(function() {

            makeSquare();

        }, 50);

    })

}